import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import crc32 from 'crc/crc32';
import { SodiumPlus, CryptographyKey } from 'sodium-plus';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  appVersion = 'v22.05.13';
  actualPage = '';
  selectedLanguage = 'it';
  productPath: string = '';
  discounts: any;
  // loggedUser: User;

  keyBuffer: Buffer;
  phpPath: string;
  imgPath: string;
  sodium: SodiumPlus;

  constructor(private http: HttpClient) {
    // only for online mode
    this.phpPath = '/php/';
    this.imgPath = '/php/immagini/';

    // only for developer mode
    //this.phpPath = 'http://localhost:3000/php/';
    //this.imgPath = 'https://metalseat.ocacloud.it/php/immagini/'
  }

  /**
    * @desc save Quote in session storage
    * @param id - Quote's id
    * @return void
  */
  saveQuote(id: string) {
    sessionStorage.setItem('SelectedQuote', id);
  }

  /**
    * @desc return session storage saved Quote id
    * @param -
    * @return saved Quote
  */
  getQuote() {
    return JSON.parse(sessionStorage.getItem('SelectedQuote'));
  }

  /**
    * @desc remove session storage saved Quote
    * @param -
    * @return void
  */
  resetQuote() {
    sessionStorage.removeItem('SelectedQuote');
  }

  saveKey(data: string) {
    this.keyBuffer = Buffer.from(data, 'utf8');
  }

  /**
    * @desc encrypt data
    * @param data - decrypted data
    * @return encrypted data
  */
  async encryptDataGlobal(data: string) {
    const crc32Key = crc32(data);
    const base64Key = btoa(crc32Key);

    if (!this.sodium) { this.sodium = await SodiumPlus.auto(); }
    const nonce = await this.sodium.randombytes_buf(24);
    const keyPair = new CryptographyKey(this.keyBuffer);
    const sKey = await this.sodium.crypto_box_secretkey(keyPair);
    const pKey = await this.sodium.crypto_box_publickey(keyPair);
    const encrypted = await this.sodium.crypto_box(base64Key, nonce, sKey, pKey);

    return (nonce.toString('hex') + encrypted.toString('hex'));
  }

  getProdPath() {
    return sessionStorage.getItem('ProdPath');
  }
  
  setProdPath(prodPath: string) {
    sessionStorage.setItem('ProdPath', prodPath);
  }

  saveSelectedAttribute(attribute: any) {
    sessionStorage.setItem('SelectedAttribute', attribute);
  }

  getSelectedAttribute() {
    return sessionStorage.getItem('SelectedAttribute');
  }
}
