import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {

  id: string = '';
  rows: any[] = [];
  loading: boolean = true;
  showMultiply = false;
  multiplyIndex = 1;
  multiplyRowId = '';
  quote: any;

  constructor(private g: GlobalService, private route: ActivatedRoute, private data: DataService, private router: Router, private auth: AuthService, private location: Location) { }

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.id = param.id;
    });

    this.quote = this.g.getQuote();

    const formData = new FormData();
    formData.append('id_prev', this.id);
    this.data.getQuoteRows(formData).subscribe(
      result => {
        this.rows = result;
        if (this.rows.length > 0) {
          this.rows.forEach((row, index, array) => {
            const formData2 = new FormData();
            formData2.append('cat', row.id_cat);
            formData2.append('fam', row.id_fam);
            formData2.append('id', row.id_ver);
            this.data.getVer(formData2).subscribe(
              result2 => {
                row.nome = result2[0].descrizione;
                if (index === array.length - 1) {
                  this.loading = false;
                  console.log('ROWS', this.rows)
                }
              },
              error => {
                console.log(error);
              });
          })
        } else {
          this.loading = false;
        }
      },
      error => {
        console.log(error);
      });
  }

  /**
   * @desc after view init, set g.actualpage. Used in header.
   * @param -
   * @return void
 */
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = 'quote';
    });
  }

  changeAttribute(id) {
    this.router.navigateByUrl('edit/' + id);
  }

  hideAtt(idRow, idAtt) {
    const rowIndex = this.rows.findIndex(row => row.id === idRow);
    const attrIndex = this.rows[rowIndex].attrs.findIndex(att => att.id_attr === idAtt);

    this.rows[rowIndex].attrs[attrIndex].attivo = !this.rows[rowIndex].attrs[attrIndex].attivo;
    const formData = new FormData();
    formData.append('id', idRow);
    formData.append('attrs', JSON.stringify(this.rows[rowIndex].attrs));
    this.data.editQuoteRow(formData).subscribe(
      result => {
        this.quote.prezzo -= (this.rows[rowIndex].attrs[attrIndex].prezzo * this.rows[rowIndex].attrs[attrIndex].qta);
        this.g.saveQuote(JSON.stringify(this.quote));
      },
      error => {
        console.log(error);
      });
  }

  deleteRow(idRow) {
    const formData = new FormData();
    formData.append('id', idRow);
    this.data.deleteQuoteRow(formData).subscribe(
      result => {
        let i = this.rows.findIndex(r => r.id === idRow);
        this.rows[i].attrs.forEach(att => {
          console.log(att)
          if(att.attivo)
            this.quote.prezzo -= (att.prezzo * att.qta);
        });
        this.quote.qta -= 1;
        this.g.saveQuote(JSON.stringify(this.quote));
        this.rows = this.rows.filter(r => r.id !== idRow);
      },
      error => {
        console.log(error);
      });
  }

  multiply() {
    this.quote.prezzo = 0;
    const rowIndex = this.rows.findIndex(row => row.id === this.multiplyRowId);
    this.rows[rowIndex].attrs.forEach(att => {
      att.qta = att.qta_suggerita * this.multiplyIndex;
      this.quote.prezzo += (att.prezzo * (att.qta_suggerita * (this.multiplyIndex)));
      this.g.saveQuote(JSON.stringify(this.quote));
    });
  }

  addQModal(n) {
    if (this.multiplyIndex + n >= 0)
      this.multiplyIndex = this.multiplyIndex + n;
  }

  addProdToQuote() {
    this.router.navigateByUrl('product');
  }

  async deleteQuote() {
    const checkSumKey = await this.g.encryptDataGlobal('TblPreventivi' + this.id);
    const formData = new FormData();
    formData.append('id', this.id);
    formData.append('field', 'stato');
    formData.append('stato', '2');
    formData.append('checkKey', checkSumKey);
    this.data.editQuote(formData).subscribe(
      result => {
        // add log
        const formDataLog = new FormData();
        formDataLog.append('email', this.auth.getUser().email);
        formDataLog.append('rag_soc', this.auth.getUser().rag_soc);
        formDataLog.append('dataora', Date.now().toString());
        formDataLog.append('operation', 'Annullamento preventivo ' + this.id);
        this.data.addLog(formDataLog).subscribe(
          async result => {
            this.g.resetQuote();
            this.router.navigateByUrl('homepage');
          },
          error => {
            console.error(error);
          }
        );
      });
  }

  back() {
    this.location.back();
  }

  addQ(attr, i, j, n) {
    if (attr.qta + (attr.qta_suggerita * n) >= 0) {
      this.rows[i].attrs[j].qta = this.rows[i].attrs[j].qta + this.rows[i].attrs[j].qta_suggerita * n;
      this.quote.prezzo += (this.rows[i].attrs[j].prezzo * (n * this.rows[i].attrs[j].qta_suggerita));
      this.g.saveQuote(JSON.stringify(this.quote));
    }
  }

  closeAndSendQuote(cd) {
    const formData = new FormData();
    formData.append('quote', JSON.stringify(this.quote));
    formData.append('user', JSON.stringify(this.auth.getUser()));
    formData.append('lang', JSON.stringify(this.g.selectedLanguage));

    this.data.generatePdf(formData).subscribe(
      async result => {
        const base64Response = await fetch(result.file);
        const blob = await base64Response.blob();
        const fileURL = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = fileURL;
        a.download = result.nome;
        a.target = "_blank";
        a.click();
      },
      error => {
        console.error('Unable to generate pdf: ', error);
      });     
  }
}
