import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  message = '';
  error = false;
  userForm: FormGroup;
  constructor(private fb: FormBuilder, private location: Location, private data: DataService, private g: GlobalService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.userForm = this.fb.group({
      ref: new FormControl('', [Validators.required]),
      azienda: new FormControl('', [Validators.required]),
      piva: new FormControl('', [Validators.required]),
      indirizzo: new FormControl('', [Validators.required]),
      citta: new FormControl('', [Validators.required]),
      prov: new FormControl('', [Validators.required]),
      stato: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$')])
    });

  }

  /**
    * @desc after view init, set g.actualpage. Used in header.
    * @param -
    * @return void
  */
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = 'signup';
    });
  }

  /**
    * @desc submit a signup request.
    *   IF SUCCESS: request submitted succesfully.
    *   OTHERWISE:
    *     IF THE TYPED EMAIL IS ALREADY USED: show 'Duplicate entry' error
    *     OTHERWISE: show server error
    * @param -
    * @return void
  */
  async onSubmit() {
    let ind = '';
    if (this.userForm.value.indirizzo) {
      ind += this.userForm.value.indirizzo + ', ';
    }
    if (this.userForm.value.citta) {
      ind += this.userForm.value.citta + ', ';
    }
    if (this.userForm.value.prov) {
      ind += this.userForm.value.prov + ', ';
    }
    if (this.userForm.value.stato) {
      ind += this.userForm.value.stato;
    }
    const checkSumKey = await this.g.encryptDataGlobal(this.userForm.value.email + this.userForm.value.ref);
    const formData = new FormData();
    formData.append('ref', this.userForm.value.ref);
    formData.append('azienda', this.userForm.value.azienda);
    formData.append('indirizzo', ind);
    formData.append('email', this.userForm.value.email);
    formData.append('piva', this.userForm.value.piva);
    formData.append('checkKey', checkSumKey);
    this.data.addUser(formData).subscribe(
      result => {
        this.error = false;
        this.userForm.reset();
        this.message = this.translate.instant('requestSend');
      },
      error => {
        console.error(error);
        this.error = true;
        if (error.detail.text.includes('Duplicate entry')) {
          this.message = this.translate.instant('emailAlreadyUsed');
        } else {
          this.message = this.translate.instant('errorOccured');
        }
      }
    );
  }

  /**
    * @desc navigate back  
    * @param -
    * @return void
  */
  goBack() {
    this.location.back();
  }
}
