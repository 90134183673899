import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import { generate } from 'node_modules/generate-password';
import { TranslateService } from '@ngx-translate/core';
import { TblUtenti } from 'src/app/entities/TblUtenti';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  CryptoJS = require('crypto-js');

  sortName: string | null = null;
  sortValue: string | null = null;
  searchEmail = '';
  searchCompany = '';
  visibleReset = false;
  dataResult: TblUtenti[];
  sortedData: TblUtenti[];
  displayedData: any[];
  listOfSearchName: string[] = [];
  loading = true;
  visiblePopover = false;
  constructor(private data: DataService, private message: NzMessageService, private g: GlobalService, private translate: TranslateService) { }

  ngOnInit() {
    // get all users
    this.data.getTable('users').subscribe(
      result => {
        this.dataResult = result as TblUtenti[];
        this.displayedData = [
          ...this.dataResult
        ];
        this.sortedData = [
          ...this.displayedData
        ];
        this.loading = false;
        this.displayedData.forEach(data => {
          if (data.pwd) {
            data.pwd = this.decryptPwd(data.pwd);
          }
          data.visible = false;
        });
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        this.loading = false;
        console.error('GET TABLE: ', error);
      }
    );
  }

  /** 
    * @desc modify User.bloccato status
    * @param index - User index
    * @return void
  */
  async lockUser(index: number) {
    const checkSumKey = await this.g.encryptDataGlobal('TblUtenti' + this.dataResult[index].email);
    const formData = new FormData();
    formData.append('email', this.dataResult[index].email);
    formData.append('field', 'bloccato');
    formData.append('checkKey', checkSumKey);
    this.data.editUser(formData).subscribe(
      result => {
        this.dataResult[index].bloccato = !this.dataResult[index].bloccato;
        this.message.create('success', 'Status changed successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.error('CHANGE SWITCH: ', error);
      }
    );
  }

  /** 
    * @desc modify User.admin status
    * @param index - User index
    * @return void
  */
  async changeAdminUser(index: number) {
    const checkSumKey = await this.g.encryptDataGlobal('TblUtenti' + this.dataResult[index].email);
    const formData = new FormData();
    formData.append('email', this.dataResult[index].email);
    formData.append('field', 'admin');
    formData.append('checkKey', checkSumKey);
    this.data.editUser(formData).subscribe(
      result => {
        this.dataResult[index].admin = !this.dataResult[index].admin;
        this.message.create('success', 'Status changed successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.error('CHANGE SWITCH: ', error);
      }
    );
  }

  /** 
    * @desc reset search
    * @param -
    * @return void
  */
  reset(): void {
    this.searchEmail = '';
    this.searchCompany = '';
    this.find();
    this.visibleReset = false;
  }

  /** 
  * @desc filter Users based on User.email or User.rag_soc
  * @param -
  * @return void
  */
  find(): void {
    this.visibleReset = true;
    if (this.searchEmail || this.searchCompany) {
      this.displayedData = this.dataResult.filter((item: TblUtenti) => item.email.toLowerCase().indexOf(this.searchEmail.toLowerCase()) !== -1
        && item.rag_soc.toLowerCase().indexOf(this.searchCompany.toLowerCase()) !== -1);
    } else {
      this.displayedData = this.dataResult;
    }
  }

  /** 
  * @desc decrypt a string
  * @param data - encrypted string
  * @return decrypted string
  */
  decryptPwd(data: string) {
    const words = this.CryptoJS.enc.Base64.parse(data);
    const textString = this.CryptoJS.enc.Utf8.stringify(words);
    return textString;
  }

  /** 
  * @desc generate a string, decrypt it and assign to User. Save it on database.
  * @param index - User index
  * @return void
  */
  async generate(index: number) {
    const password = generate({
      length: 12,
      numbers: true,
      symbols: true,
      uppercase: true,
      lowercase: true
    });
    const crypt = this.CryptoJS.enc.Base64.stringify(this.CryptoJS.enc.Utf8.parse(password));
    const checkSumKey = await this.g.encryptDataGlobal('TblUtenti' + this.dataResult[index].email);
    const formData = new FormData();
    formData.append('email', this.dataResult[index].email);
    formData.append('checkKey', checkSumKey);
    formData.append('pwd', crypt);
    formData.append('field', 'pwd');
    this.data.editUser(formData).subscribe(
      result => {
        this.dataResult[index].pwd = crypt;
        this.displayedData[index].pwd = this.decryptPwd(this.displayedData[index].pwd);
        this.message.create('success', 'Password changed successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.error('GENERATEPWD: ', error);
      }
    );
  }

  /** 
  * @desc send a mail with credentials
  * @param index - User index
  * @return void
  */
  sendCredentials(index: number) {
    const formData = new FormData();
    formData.append('receiper', this.dataResult[index].email);
    formData.append('object', this.translate.instant('credentialsObj'));
    formData.append('body', this.translate.instant('credentialsBody'));
    formData.append('pwd', this.dataResult[index].pwd);
    this.data.sendCredentials(formData).subscribe(
      result => {
        this.message.create('success', 'Mail send successfully', { nzDuration: 5000 });
        if (this.dataResult[index].bloccato) {
          this.dataResult[index].bloccato = false;
        }
      },
      error => {
        this.message.create('error', 'A backend error occured', { nzDuration: 5000 });
        console.error('SENDMAIL: ', error);
      }
    );
  }

  /** 
  * @desc copy string
  * @param txt - the string to copy
  * @return void
  */
  copyTxt(txt: string) {
    const t = document.createElement("textarea");
    t.value = txt;
    document.body.appendChild(t);
    t.select();
    document.execCommand("copy");
    document.body.removeChild(t);
  }
}
