import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalService } from '../shared/global.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private baseUrl: string;
  private header = new HttpHeaders();

  constructor(private http: HttpClient, private globalService: GlobalService) {
    this.baseUrl = this.globalService.phpPath;
  }

  /**
  * @desc get all data in requested table
  * @param request -> contains request
  * @return Observable<Error | any>, any if it works, error otherwise
  */
  getTable(request: string): Observable<Error | any[]> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any[]>(this.baseUrl + '/getTable.php', request, { headers: this.header })
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
      );
  }

  /**
  * @desc call backend and add row to TblUser
  * @param formData -> contains request
  * @return Observable<Error | any>, any if it works, error otherwise
  */
  addUser(formData: FormData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Users/addUser.php', formData, { headers: this.header })
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          return throwError(err);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
      );
  }

  /**
  * @desc call backend and edit TblUser row
  * @param formData -> contains request
  * @return Observable<Error | any>, any if it works, error otherwise
  */
  editUser(formData: FormData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Users/editUser.php', formData, { headers: this.header })
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
      );
  }

  /**
  * @desc call backend and get key storaged in it
  * @param -
  * @return Observable<Error | any>, any if it works, error otherwise
  */
  getKey(): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.get<any>(this.baseUrl + '/getKey.php', { headers: this.header })
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
      );
  }

  /**
    * @desc call backend and add row to TblLog
    * @param formData -> contains request
    * @return Observable<Error | any>, any if it works, error otherwise
  */
  addLog(formData: FormData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Logs/addLog.php', formData, { headers: this.header })
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          return throwError(err);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
      );
  }

  /**
  * @desc call backend and add row to TblQuote
  * @param formData -> contains request
  * @return Observable<Error | any>, any if it works, error otherwise
  */
  addQuote(formData: FormData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Quotes/addQuote.php', formData, { headers: this.header })
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          return throwError(err);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
      );
  }

  /**
    * @desc call backend and check if exist a user's "IN PROGRESS" Quote
    * @param formData -> contains request
    * @return Observable<Error | any>, any if it works, error otherwise
  */
  checkLastQuote(formData: FormData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Quotes/checkLastQuote.php', formData, { headers: this.header })
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          return throwError(err);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
      );
  }

  /**
  * @desc call backend and edit TblQuote row
  * @param formData -> contains request
  * @return Observable<Error | any>, any if it works, error otherwise
  */
  editQuote(formData: FormData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Quotes/editQuote.php', formData, { headers: this.header })
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          return throwError(err);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
      );
  }

  /**
    * @desc call backend and send an email with credentials
    * @param formData -> contains request
    * @return Observable<Error | any>, any if it works, error otherwise
  */
  sendCredentials(formData: FormData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Users/sendCredentials.php', formData, { headers: this.header })
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          return throwError(err);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
      );
  }

  getFamiliesByCat(cat): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Products/getFamiliesByCat.php', cat, { headers: this.header })
    .pipe(catchError((err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // A client-side or network error occurred
        return throwError(err);
      } else {
        // A backend error occured (err.status = 500)
        const details = { detail: err.error, status: err.status };
        return throwError(details);
      }
    })
    );
  }

  getVersionsByFam(formData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Products/getVersionsByFam.php', formData, { headers: this.header })
    .pipe(catchError((err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // A client-side or network error occurred
        return throwError(err);
      } else {
        // A backend error occured (err.status = 500)
        const details = { detail: err.error, status: err.status };
        return throwError(details);
      }
    })
    );
  }

  getAttrsByVer(formData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Products/getAttrsByVer.php', formData, { headers: this.header })
    .pipe(catchError((err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // A client-side or network error occurred
        return throwError(err);
      } else {
        // A backend error occured (err.status = 500)
        const details = { detail: err.error, status: err.status };
        return throwError(details);
      }
    })
    );
  }
  
  getProducts(formData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Products/getProducts.php', formData, { headers: this.header })
    .pipe(catchError((err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // A client-side or network error occurred
        return throwError(err);
      } else {
        // A backend error occured (err.status = 500)
        const details = { detail: err.error, status: err.status };
        return throwError(details);
      }
    })
    );
  }  

  addQuoteRow(formData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Quotes/addQuoteRow.php', formData, { headers: this.header })
    .pipe(catchError((err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // A client-side or network error occurred
        return throwError(err);
      } else {
        // A backend error occured (err.status = 500)
        const details = { detail: err.error, status: err.status };
        return throwError(details);
      }
    })
    );
  }  

  getQuoteRows(formData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Quotes/getQuoteRows.php', formData, { headers: this.header })
    .pipe(catchError((err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // A client-side or network error occurred
        return throwError(err);
      } else {
        // A backend error occured (err.status = 500)
        const details = { detail: err.error, status: err.status };
        return throwError(details);
      }
    })
    );
  }  

  getVer(formData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Products/getVersion.php', formData, { headers: this.header })
    .pipe(catchError((err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // A client-side or network error occurred
        return throwError(err);
      } else {
        // A backend error occured (err.status = 500)
        const details = { detail: err.error, status: err.status };
        return throwError(details);
      }
    })
    );
  }  

  getQuoteRowById(formData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Quotes/getQuoteRowById.php', formData, { headers: this.header })
    .pipe(catchError((err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // A client-side or network error occurred
        return throwError(err);
      } else {
        // A backend error occured (err.status = 500)
        const details = { detail: err.error, status: err.status };
        return throwError(details);
      }
    })
    );
  } 

  editQuoteRow(formData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Quotes/editQuoteRow.php', formData, { headers: this.header })
    .pipe(catchError((err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // A client-side or network error occurred
        return throwError(err);
      } else {
        // A backend error occured (err.status = 500)
        const details = { detail: err.error, status: err.status };
        return throwError(details);
      }
    })
    );
  } 

  deleteQuoteRow(formData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Quotes/deleteQuoteRow.php', formData, { headers: this.header })
    .pipe(catchError((err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // A client-side or network error occurred
        return throwError(err);
      } else {
        // A backend error occured (err.status = 500)
        const details = { detail: err.error, status: err.status };
        return throwError(details);
      }
    })
    );
  }
  
  getQuoteRowsData(formData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Quotes/getQuoteRowsData.php', formData, { headers: this.header })
    .pipe(catchError((err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // A client-side or network error occurred
        return throwError(err);
      } else {
        // A backend error occured (err.status = 500)
        const details = { detail: err.error, status: err.status };
        return throwError(details);
      }
    })
    );
  }  

  checkDiscounts(formData): Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/Users/checkDiscounts.php', formData, { headers: this.header })
    .pipe(catchError((err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // A client-side or network error occurred
        return throwError(err);
      } else {
        // A backend error occured (err.status = 500)
        const details = { detail: err.error, status: err.status };
        return throwError(details);
      }
    })
    );
  }  

  generatePdf(formData: FormData) : Observable<Error | any> {
    this.header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseUrl + '/pdfmodels/model1.php', formData, { headers: this.header })
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
      );
  }
}